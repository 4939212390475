(function($){

    function humanFileSize(bytes, si) {
        var thresh = si ? 1000 : 1024;
        if(Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
            : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1)+' '+units[u];
    }

    let Media = function ( prop ) {

        let self = this;

        this.id = typeof prop.id === 'undefined' ? null : prop.id;
        this.name = typeof prop.name === 'undefined' ? null : prop.name;
        this.title = typeof prop.title === 'undefined' ? null : prop.title;
        this.dir = {
            year: typeof prop.dir_year === 'undefined' ? null : prop.dir_year,
            month: typeof prop.dir_month === 'undefined' ? null : prop.dir_month
        };
        this.size = typeof prop.size === 'undefined' ? null : prop.size;

        this.url = function(options){
            let url = [ MG.uploads.url, self.dir.year, self.dir.month, self.name ].join('/');
            return imageUrl( url, options );
        }

    };

    let MediaGallery = function () {

        let self = this;

        this.uploads = { url: APP_URL + '/storage/uploads' };

        this.$gallery = $('.media-gallery');
        this.$preview = $('.media-sidebar').parent();

        this.$media = this.$gallery.find('.media');

        this.options = {
            select: this.$gallery.data('select')
        };

        this.selection = [];

        this.clearSelection = function(){
            self.selection.length = 0;
            self.$gallery.find('.check').prop('checked', false);
            self.$preview.hide();
        };


        let $lastMedia = this.$media.last();

        let $searchTimeout = null;
        let $searchInput = $('#search-section input');

        let mediaGalleryFetchOptions = {
            q: $searchInput?.val()?.length ? $searchInput.val() : null,
            after: $lastMedia.length ? $lastMedia.data('media').id : null,
            limit: 30
        };

        let $loadMoreSection = $('#media-load-more-section');
        $loadMoreSection.find('.btn').click(function(){
            self.load( mediaGalleryFetchOptions );
        });

        function search() {
            if ($searchTimeout !== null) {
                clearTimeout($searchTimeout)
            }

            $searchTimeout = setTimeout(function () {
                self.$gallery.html("")
                self.load({ q: $searchInput.val(), limit: 30 })
            }, 500)
        }

        $searchInput.on('input', search)

        if( this.$media.length < mediaGalleryFetchOptions.limit ){
            $loadMoreSection.hide();
        }


        this.deleteMedia = function( mediaID, callback ){

            if( typeof callback === 'undefined') callback = function(){};

            axios.request({
                method: 'delete',
                url: UDL.mediaUrl + '/' + mediaID,
            }).then(callback);
        };

        this.load = function( options ){

            $loadMoreSection.addClass('loading');

            options = $.extend({
                after: null,
                limit: 30,
            }, options);

            axios({
                method: 'get',
                url: UDL.mediaUrl,
                params: options,
            })
                .then(function (response) {
                    console.log(response.data)

                    $loadMoreSection.removeClass('loading');

                    response.data.forEach(function( image ){
                        self.addNewImage( image, { mode: 'append' } );
                    });


                    if( response.data.length < options.limit){
                        $loadMoreSection.hide();
                        return;
                    }

                    mediaGalleryFetchOptions.after = response.data[ response.data.length - 1 ].id;

                })
                .catch(function (response) {
                    $loadMoreSection.removeClass('loading');
                });

        };


        this.$gallery.on('click', '.action-media-delete', function(e){
            if( !confirm('Are you sure to PERMANENTLY DELETE this file?') ) return;

            let $btn = $(this);
            let $item = $btn.parents('.media-wrap');
            let image = $item.find('.media').data('media');

            $btn.enable_loading().prop_disable();

            self.deleteMedia( image.id, function(){
                $item.parent().remove();
            });

        });


        this.$gallery.on('change', '.check', function(){

            let $input = $(this);
            self.selection.length = 0;

            // Only single selection allowed if option is "single"
            if( self.options.select !== 'multiple' ){
                if( $input.prop('checked') === true ){
                    self.$gallery.find('.check').not( $input ).prop('checked', false);
                }
            }

            let lastSelection = new Media( $input.parent().data('media') );

            let media = self.$gallery.find('.check:checked').parent();

            if( media.length === 0 ){
                $(window).trigger({
                    type: 'media.select',
                    selection: [],
                    lastSelection: lastSelection
                });
            }

            media.each(function(i, e){
                self.selection.push( new Media( $(e).data('media') ) );
                if( media.length - 1 === i ){
                    $(window).trigger({
                        type: 'media.select',
                        selection: self.selection,
                        lastSelection: lastSelection
                    });
                }
            });

        });


        let previewUpdateTimer = null;
        $('.preview-title').keyup(function(){

            let $input = $(this);

            if( previewUpdateTimer ){
                clearTimeout( previewUpdateTimer );
                previewUpdateTimer = null;
            }

            previewUpdateTimer = setTimeout(function(){

                let media = self.$preview.data('media');

                media.title = $input.val();
                self.$preview.enable_loading();

                self.update( media, function(){
                    self.$preview.disable_loading();
                }, function(){
                    self.$preview.disable_loading();
                } );

            }, 300);
        });


        this.update = function( media, callbackSuccess, callbackFail ){
            if( typeof media.id === 'undefined' ) return;
            if( typeof callbackSuccess !== 'function' ) callbackSuccess = function(){};
            if( typeof callbackFail !== 'function' ) callbackFail = function(){};

            axios({
                method: 'put',
                url: UDL.mediaUrl + '/' + media.id,
                data: media,
            })
                .then( callbackSuccess )
                .catch( callbackFail );

        };


        // Show Preview on right side ===================
        $(window).on('media.select', function(e){

            let media = e.lastSelection;

            self.$preview.data('media', media);

            self.$preview.find('.img-preview').attr('src', media.url({resize: 320}))
                .parent().attr('href', media.url());

            self.$preview.find('.file-name').html( media.name );
            self.$preview.find('.size').html( humanFileSize( media.size, true ) );
            self.$preview.find('.preview-title').val( media.title );

            // self.$preview.find('form').each(function(){
            //     let $form = $(this);
            //     $form.attr('action', $form.data('action') + '/' + media.id);
            // });

            // self.$preview.find('.delete-media').click(function(e){
            //
            //     let $btn = $(this);
            //     // $btn.prop('disabled', true);
            //
            //     console.log( media );
            //
            //     // return;
            //
            //     axios({
            //         method: 'delete',
            //         url: UDL.mediaUrl + '/' + media.id,
            //     })
            //         .then(function (response) {
            //
            //             self.$media.each(function(i, e){
            //                 let $item = $(e);
            //                 console.log( $item.data('media').id, media.id );
            //                 if( $item.data('media').id === media.id ) $item.remove();
            //             });
            //
            //             self.$preview.hide();
            //             $btn.prop('disabled', false);
            //
            //         }).catch(function(){
            //             self.$preview.hide();
            //             $btn.prop('disabled', false);
            //         });
            // });

            self.$preview.show();

            if( !MediaGallery.isTopPage() ){
                window.top.selectMediaEvent(e);
            }

        });


        this.initUploader = function(){
            // Handle File Uploads ==================================
            let $galleryWrap = $('#gallery-file-droppable');
            let $fileUploadInput = $galleryWrap.find('#upload-file');
            let $galleryManualUpload = $('#gallery-manual-upload');

            $galleryManualUpload.on( 'click', function(e) {
                e.preventDefault();
                $fileUploadInput.click();
            } );

            $fileUploadInput.change(drop);

            $galleryWrap.on('dragover dragenter', drag);
            $galleryWrap.find('.droppable-area').on('dragover dragenter dragleave', drag);
            $galleryWrap.on('drop', drop);

            function drag(e){
                e.stopPropagation();
                e.preventDefault();
                if( e.type === "dragover" || e.type === 'dragenter' ) $galleryWrap.addClass('hover');
                else  $galleryWrap.removeClass('hover');
            }

            function drop(e){

                drag(e); // Cancel dragging styles.

                let originalEvent = e.originalEvent;
                let files = originalEvent.target.files || originalEvent.dataTransfer.files;

                if( typeof files !== 'undefined' || files.length > 0 ){
                    upload( files );
                }

                $fileUploadInput.val('');

            }


            let numberOfFilesToUpload = 0;
            let numberOfFilesUploadedOrFailed = 0;

            function upload( files ){

                numberOfFilesToUpload += files.length;
                console.log("To Upload: ", numberOfFilesToUpload);

                $galleryWrap.addClass('uploading');

                function progress(){
                    numberOfFilesUploadedOrFailed++;
                    console.log("Upload OR Fail: ", numberOfFilesUploadedOrFailed );
                    if( numberOfFilesToUpload === numberOfFilesUploadedOrFailed ){
                        $galleryWrap.removeClass('uploading');
                    }
                }

                for( let i = 0; i < files.length; i++ ){
                    let file = files[ i ];

                    let formData = new FormData();
                    formData.append('files[]', file);

                    axios({
                        method: 'post',
                        url: $('#form-image-upload').attr('action'),
                        data: formData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                        .then(function (response) {
                            //handle success
                            response.data.forEach( self.addNewImage );
                            progress();
                        })
                        .catch(function (response) {
                            //handle error
                            progress();
                        });

                }
            }

        };

        this.addNewImage = function( image, options ){

            options = $.extend({
                mode: 'prepend' // prepend | append | replace
            }, options);
            let media = new Media( image );

            let $image = $('<span class="media-image"/>')
                .append(
                    $('<img/>')
                        .attr({
                            'src': media.url({crop:[128, 128]}),
                            'title': media.title,
                            'style': 'width:128px'
                        })
                )
                .append('<i class="icon-checked fas fa-check"></i>');

            let $media = $('<div class="col mb-3"/>')
                .append(
                    $('<label/>')
                        .addClass('media')
                        .data('media', image)
                        .append('<input class="check" type="checkbox" name="media[]">')
                        .append($image)
                );

            if( options.mode === 'prepend' ){
                self.$gallery.prepend($media);
            }else if( options.mode === 'append' ){
                self.$gallery.append($media);
            }else if( options.mode === 'replace' ){
                self.$gallery.html($media);
            }

        };


        this.initUploader();


        if( self.$media.length > 0 ){
            setTimeout(function(){
                $(window).trigger({
                    type: 'media.select',
                    selection: self.selection,
                    lastSelection: new Media( self.$media.first().data('media') )
                });
            }, 10);
        }else{
            self.$preview.hide();
        }

        $(window).trigger('media.loaded', this);

    };

    MediaGallery.GET = function(param) {
        var result = null,
            tmp = [];
        var items = location.search.substr(1).split("&");
        for (var index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === param) result = decodeURIComponent(tmp[1]);
        }
        return result;
    };


    MediaGallery.isHeadless = function(){
        return MediaGallery.GET('headless') !== null;
    };

    MediaGallery.isGalleryPage = function(){
        return $('.media-gallery').length > 0;
    };

    MediaGallery.isTopPage = function(){
        return window.top === window;
    };

    MediaGallery.resizeModal = function(){
        let $modal = $('#modal-media');
        let spaceUsed = $modal.find('.modal-header').outerHeight() + $modal.find('.modal-footer').outerHeight();
        $modal.find('.modal-media-frame').height( $(window).height() - spaceUsed - 80 );
    };

    MediaGallery.init = function(){

        $(document).ready(function(){

            if( MediaGallery.isGalleryPage ){
                window.MG = new MediaGallery();
                top.MG = window.MG;
            }

            if( MediaGallery.isTopPage() ){
                window.selectMediaEvent = function(e){
                    $(window).trigger({
                        type: 'top.media.select',
                        selection: e.selection,
                        lastSelection: e.lastSelection
                    })
                };
            }


            // Modal Linking ================

            if( MediaGallery.isTopPage() ){

                let $modal = $('#modal-media');

                $(window).resize( MediaGallery.resizeModal );
                $modal.on('shown.bs.modal', function(){ setTimeout( MediaGallery.resizeModal, 10 ); });

                $(window).on('top.media.select', function(e){
                    MediaGallery.selection = e.selection;
                });

                $modal.on('hidden.bs.modal', function () {
                    MG.clearSelection();
                });

                $modal.find('.media-insert').click(function(){
                    if( MediaGallery.openCallback ){
                        MediaGallery.openCallback( MediaGallery.selection );
                        MediaGallery.openCallback = null;
                    }

                    $modal.modal('hide');
                });

            }
        });


    };


    MediaGallery.openCallback = null;
    MediaGallery.open = function( callback, options ){
        if( MediaGallery.isTopPage() ){

            // http://localhost:8000/admin/media?headless=1

            options = $.extend({
                select: 'single'
            }, options);

            MG.options.select = options.select === 'multiple' ? 'multiple' : 'single';

            let $modal = $('#modal-media');
            $modal.find('.modal-media-frame');
            $modal.modal({
                backdrop: 'static'
            });

            MediaGallery.openCallback = callback;
        }
    };

    MediaGallery.init();

    window.MediaGallery = MediaGallery;

})(jQuery);
