// ====================================================
// ===== Application Dependencies & Libraries =========
// ====================================================

require('./bootstrap');



// =================================
// ===== Utility Functions =========
// =================================

require('../common/utils/confirm');
require('../common/utils/action-delete');
require('../common/utils/number-format');
require('../common/utils/image-url');
require('../common/utils/prop-enable-disable');
require('../common/utils/loading');



// ================================
// ===== Common Components ========
// ================================

require('../common/components/select2');
require('../common/components/daterange');



// ================================
// ==== Application Components ====
// ================================

require('./components/media-gallery');
require('./components/fields/all');
require('./components/product-mark-as');
require('./components/sidebar');
require('./components/css-editor');



// Load CSS editor scripts only if it is required.
if( $('#salesChart').length ) require('./components/charts');
