
$(document).ready(function () {

    $('.sidebar-arrow').click(function (e) {
        e.preventDefault();
        $('.sidebar').toggleClass('left');
        $('.page').toggleClass('padding-small');
        $(this).toggleClass('rotate');
    });

});


