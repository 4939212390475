$(document).ready(()=>{
    const $inputs = $('[data-init-daterange]');
    $inputs.each((i, e) => {
        const $input = $(e);
        const options = $input.data('init-daterange');
        if( typeof options.ranges !== 'undefined' ){
            for( const i in options.ranges ){
                if( !options.ranges.hasOwnProperty( i ) ) continue;
                for( const j in options.ranges[i] ){
                    if( !options.ranges[i].hasOwnProperty( j ) ) continue;
                    options.ranges[i][j] = moment(options.ranges[i][j]);
                }
            }
        }
        $input.daterangepicker( $.extend({
            locale: {
                format: 'DD/MM/YYYY'
            }
        }, options ) );
    });
});