(function($){

    $(document).ready(function(){

        $('.field-slider').each(function (i, el){

            let $el = $( el );
            let options = {};

            $el.data('slider-instance', new Slider( $el, options ));

        });

    });

    function Slider( $element, options ){

        let slider = this;

        let slides = $element.data('slides');
        let fields = $element.data('fields');

        let $primarySlide = $element.find('.primary-slide');
        let $thumbs = $element.find('.slider-thumbs');
        let $field = $element.find('.field-value');
        let $btnAdd = $element.find('.btn-slide-add');
        let $btnDelete = $element.find('.btn-slide-delete');
        let $reorderModal = $element.find('.modal');
        let $fields = $element.find('.slide-field');

        function orderTemplate( slide ){
            return '' +
            '<tr>' +
            '    <td>' +
            '        <img style="width: 80px" src="' + _.escape( imageUrl( slide.image, { crop: 80 } ) ) + '">' +
            '    </td>' +

                ( fields.length > 0 ? '<td>' + _.escape( typeof slide[fields[0]] !== 'undefined' ? slide[fields[0]] : '' ) + '</td>' : '' ) +

            '    <td width="120" class="text-right">' +
            '        <button class="btn btn-move-up"><i class="fas fa-arrow-up"></i></button>' +
            '        <button class="btn btn-move-down"><i class="fas fa-arrow-down"></i></button>' +
            '    </td>' +
            '</tr>'
        }


        function thumbTemplate( slide ){
            return $('<img>').attr({
                class: "img-fluid rounded",
                style: "height: 36px",
                src: imageUrl( slide.image, { crop: 120 } )
            })
        }


        function primaryTemplate( slide ){
            return $('<img>').attr({
                class: "img-fluid rounded",
                src: imageUrl( slide.image, { resize: 720 } )
            })
        }

        slider.currentIndex = 0;

        slider.goto = function( index ){

            if( slides.length === 0 ){
                $primarySlide.html(primaryTemplate({
                    image: encodeURIComponent( 'https://via.placeholder.com/800x200.jpg/fff/aaa?text=Add+new+slide.' )
                }));
                // slide-field-wrap
                $fields.parent().hide();
                setFields();
                return;
            }

            $fields.parent().show();

            if( index > slides.length - 1 ){
                index = slides.length - 1;
            }else if( index < 0 ){
                index = 0;
            }

            slider.currentIndex = index;
            $primarySlide.html( primaryTemplate( slides[ index ] ) );
            setFields( slides[ index ] );
            $thumbs.children().removeClass('active').eq( index ).addClass('active');

        };

        function setFields( slide ){

            if( typeof slide === 'undefined' ) return;

            fields.forEach(function( field ){
                let value = '';

                if( typeof slide[ field ] !== 'undefined' ){
                    value = slide[ field ];
                }

                // console.log( $fields.filter('[data-field="' + field + '"]') );

                $fields.filter('[data-field="' + field + '"]').val( value );

            });
        }


        slider.updateValue = function(){
            $field.val( JSON.stringify( slides ) );
        };


        slider.addSlide = function(slide){
            slides.push( slide );
            addThumb( slide );
            slider.goto( slider.currentIndex );
        };


        slider.deleteSlide = function(){
            slides.splice(slider.currentIndex, 1);
            $thumbs.children().eq( slider.currentIndex ).remove();
            slider.goto( slider.currentIndex );
            setTimeout( slider.updateValue, 10 );
        };


        function addThumb(slide){
            setTimeout( slider.updateValue, 10 );
            $thumbs.append( thumbTemplate( slide ) );
        }


        function renderSlider(){
            $thumbs.html('');
            slides.forEach( addThumb );
            slider.goto(slider.currentIndex);
        }


        function renderReorder(){
            let $tbody = $reorderModal.find('table tbody').html('');
            slides.forEach(function( slide ){
                $tbody.append( orderTemplate( slide ) );
            });
        }


        // Final Initialization ===========

        $thumbs.on('click', ' > *', function(e){
            slider.goto( $(this).index() );
        });

        $fields.keyup(function(){
            let $field = $(this);
            slides[ slider.currentIndex ][ $field.data('field') ] = $field.val();
            slider.updateValue();
        });

        $btnAdd.click(function(){

            MediaGallery.open(function( media ){
                if( typeof media === 'undefined' || media.length === 0 ) return;

                media.forEach(function( image ){

                    let slide = {image: image.url()};

                    fields.forEach(function( field ){
                        slide[ field ] = '';
                        if( typeof image[ field ] !== 'undefined' ){
                            slide[ field ] = image[ field ];
                        }
                    });

                    console.log( slide );

                    slider.addSlide( slide );
                });

            }, { select: 'multiple' });
        });

        $btnDelete.click(function(){
            if( !confirm('Are sure to delete?') ) return;
            slider.deleteSlide();
        });

        $reorderModal.on('show.bs.modal', renderReorder);
        $reorderModal.on('hide.bs.modal', renderSlider);

        $reorderModal.on('click', '.btn-move-up', function(){
            let index = $(this).parents('tr').index();
            if( index > 0 ){
                slides.splice(index - 1, 0, slides.splice(index, 1).pop());
            }
            renderReorder();
        });

        $reorderModal.on('click', '.btn-move-down', function(){
            let index = $(this).parents('tr').index();
            if( index < slides.length - 1 ) {
                slides.splice(index + 1, 0, slides.splice(index, 1).pop());
            }
            renderReorder();
        });


        renderSlider();

    }


})(jQuery);
