/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    window.moment = require('moment');

    require('bootstrap');
    require('chart.js/dist/Chart.bundle');
    require('select2');
    require('codemirror');
    require('jquery-ui/dist/jquery-ui.js');
    require('../common/components/summernote/dist/summernote-bs4.min.js');
    require('../common/components/summernote-cleaner/summernote-cleaner.js');
    require('bootstrap-daterangepicker');


    // Codemirror ----------------------
    window.CodeMirror = require('codemirror/lib/codemirror');
    require('codemirror/mode/css/css');

} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios').default;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}


