(function ($) {
    window.imageUrl = function( url, params ){

        if( typeof params !== 'undefined' ){
            for( let method in params ){

                if( !params.hasOwnProperty( method ) ) continue;

                let p = {w: null, h: null};
                let param = params[ method ];
                if( typeof param === 'object' ){
                    if( param.length === 1 ){
                        p.w = param[0];
                    }else{
                        p.w = param[0];
                        p.h = param[1];
                    }
                }else if( typeof param === 'number' ){
                    p.w = p.h = param;
                }

                let segments = [];
                for( let i in p ){
                    if( !p.hasOwnProperty( i ) ) continue;
                    if( p[i] ){
                        segments.push( i + '' + p[i] );
                    }
                }

                let newUrl = APP_URL + '/img/' + method + '/' + segments.join('/') + '/';
                newUrl += url.replace(APP_URL, '').replace(/^\/*/, '');

                return newUrl;
            }

        }

        return url;

    }
})(jQuery);