(function($){

    $(document).ready(function(){

        $('.field-gallery').each(function (i, el){

            let $el = $( el );
            let options = {};

            $el.data('gallery-instance', new Gallery( $el, options ));

        });

    });


    function Gallery( $element ){

        let gallery = this;

        console.log( $element );

        let images = [];
        let sortableImageArray = [];
        let $input = $element.find('.field-value');
        let $btnAdd = $element.find('.btn-image-add');
        let $btnDeleteAll = $element.find('.btn-gallery-clear');
        let $gallery = $element.find('.gallery');
        let $reorderModal = $element.find('.modal');
        let $reorderModalGallery = $reorderModal.find('.reorder-gallery')
        let $reorderModalDone = $reorderModal.find('.btn-done')


        function thumbTemplate( image, draggable = false ){
            let $img = $('<img>').attr({
                class: "img-thumbnail",
                src: imageUrl( image, {crop: [ 140, 140 ]} )
            });

            let $btn = $('<a class="remove-image" href="#"><i class="fas fa-times"></i></a>');

            $div = $('<div class="image" ' + (draggable ? 'draggable data-image="'+image+'"' : '') + ' />').append($img);
            if (!draggable) $div.append($btn);
            return $div;
        }


        this.updateValue = function(){
            console.log( images );
            $input.val( JSON.stringify( images ) );
        };

        this.addImage = function( image, appendToSortableImageArray = true ){
            images.push( image );
            if (appendToSortableImageArray){
                sortableImageArray = [...images];
            }
            $gallery.append( thumbTemplate( image ) );
            $reorderModalGallery.append( thumbTemplate( image, true ) );
            gallery.updateValue();
        };

        this.removeImage = function(index){
            $gallery.find('.image').eq( index ).remove();
            $reorderModalGallery.find('.image').eq( index ).remove();
            if( typeof images[ index ] !== 'undefined' ){
                images.splice( index, 1 );
            }
            sortableImageArray = [...images];
            gallery.updateValue();
        };

        this.removeAllImages = function(removeSortableImageArray = true){
            $gallery.find('.image').remove();
            $reorderModalGallery.find('.image').remove();
            images.length = 0;
            if (removeSortableImageArray) {
                sortableImageArray.length = 0
            }
            gallery.updateValue();
        };


        $gallery.on('click', '.remove-image', function(e){
            e.preventDefault();

            if( !confirm('Are sure to delete?') ) return;

            let $btn = $(this);
            gallery.removeImage( $btn.parent().index() );
        });


        $btnAdd.click(function(e){
            e.preventDefault();
            MediaGallery.open(function( images ){
                if( typeof images === 'undefined' || images.length === 0 ) return;
                images.forEach(function( image ){
                    gallery.addImage( image.url() );
                });
            }, { select: 'multiple' });
        });

        $btnDeleteAll.click( function (e){
            e.preventDefault();
            if( !confirm('Are sure to delete all images?') ) return;
            gallery.removeAllImages();
        });

        if ($element.data('images') !== '') {
            $element.data('images')?.forEach(function ( image ) {
                gallery.addImage( image );
            })
        }

        this.handleSortChange = function (event, ui) {
            sortableImageArray = $reorderModalGallery.find('.image').map((idx, el) => el.getAttribute('data-image')).get()
        }

        this.handleDoneReordering = function () {
            gallery.removeAllImages(false);
            sortableImageArray.forEach(function (image) {
                console.log(image)
                gallery.addImage( image, false );
            });
        }

        $reorderModalDone.on('click', gallery.handleDoneReordering);

        $reorderModalGallery.sortable({
            stop: gallery.handleSortChange
        });

    }


})(jQuery);
