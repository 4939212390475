(function($){
    $('.product-mark-status a').click(function(e){
        e.preventDefault();
        const $link = $(this);
        const status = $link.data('status');
        const url = $link.attr('href');
        const $form = $('<form method="post"/>').hide().attr('action', url);

        $form.append( $('<input name="_token"/>').attr( 'value', $('meta[name="csrf-token"]').attr('content') ) )
            .append( $('<input name="_method"/>').attr( 'value', 'put' ) )
            .append( $('<input name="status"/>').attr( 'value', status ) )
            .appendTo( 'body' )
            .submit();

    });
})(jQuery);